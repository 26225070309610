import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

export function initializeSliders() {
  try {
    //News and insights carousal
    const swiperCarousal = document.querySelector(".swiper");
    if (swiperCarousal) {
      const customSwiper = new Swiper(swiperCarousal, {
        direction: "horizontal",
        slidesPerView: 1.05,
        spaceBetween: 0 /* Ensure equal gap between slides */,
        loop: false,
        breakpoints: {
          992: {
            slidesPerView: 2,
            spaceBetween: 2,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 2,
          },
        },
      });
    }

    //Related news slider
    const swiper2Carousal = document.querySelector(".swiper2");
    if (swiper2Carousal) {
      const news = new Swiper(swiper2Carousal, {
        direction: "horizontal",
        slidesPerView: 1.1,
        spaceBetween: 20 /* Ensure equal gap between slides */,
        loop: false,
        breakpoints: {
          768: {
            slidesPerView: 1.1,
          },
          980: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        },
      });
    }

    //Sustainability slider
    const swiper3Carousal = document.querySelector(".swiper3");
    if (swiper3Carousal) {
      const news = new Swiper(swiper3Carousal, {
        direction: "horizontal",
        slidesPerView: 1.1,
        spaceBetween: 20 /* Ensure equal gap between slides */,
        loop: false,
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
      });
    }

    //Products section slider
    const productCarousal = document.querySelectorAll(".swiper-product");

    if (productCarousal) {
      productCarousal.forEach((element) => {
        const productSwiper = new Swiper(element, {
          slidesPerView: 4, // Default for large screens
          spaceBetween: 5, // Adjust this for spacing between slides
          watchSlidesProgress: true,
          slideFullyVisibleClass: "swiper-slide-fully-visible",
          breakpoints: {
            320: {
              slidesPerView: 1.2, // Small mobile screens
              spaceBetween: 15,
            },
            480: {
              slidesPerView: 1.2, // Mobile screens
              spaceBetween: 15,
            },
            640: {
              slidesPerView: 1.5, // Small tablets
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2.5, // Tablets
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 3, // Small desktops
              spaceBetween: 15,
            },
            1200: {
              slidesPerView: 4, // Large desktops
              spaceBetween: 15,
            },
          },
        });

        //update the swiper when tab changes
        document
          .querySelectorAll('button[data-bs-toggle="tab"]')
          .forEach((tab) => {
            tab.addEventListener("shown.bs.tab", function (e) {
              setTimeout(() => {
                productSwiper.update(); // Update Swiper after the tab becomes visible
              }, 200);
            });
          });
      });
    }

    //About page slider
    /*  const updateNavigationButtons = () => {
                    const isMobile = window.screen.width < 768;
                    const aboutSlider = document.querySelector('.about-swiper').swiper; // Get the Swiper instance
                    
                    if (aboutSlider) {
                      // Update the navigation buttons based on screen size
                      console.log(aboutSlider);
                      aboutSlider.params.navigation.nextEl = isMobile ? ".swiper-button-next-mobile" : ".swiper-button-next-desktop";
                      aboutSlider.params.navigation.prevEl = isMobile ? ".swiper-button-prev-mobile" : ".swiper-button-prev-desktop";
                      
                      // Reinitialize the navigation buttons
                      aboutSlider.navigation.destroy();  // Destroy current navigation
                      aboutSlider.navigation.init();     // Reinitialize navigation with updated buttons
                      aboutSlider.navigation.update();   // Update Swiper to apply changes
                    }
                  }; */

    const initAboutSlider = () => {
      const aboutSwiper = document.querySelector(".about-swiper");
      if (aboutSwiper) {
        const aboutSlider = new Swiper(aboutSwiper, {
          modules: [Navigation, Pagination],
          autoplay: false,
          speed: 500,
          slidesPerView: 1,
          direction: "horizontal",
          navigation: {
            nextEl:
              window.screen.width < 768
                ? ".swiper-button-next-mobile"
                : ".swiper-button-next-desktop",
            prevEl:
              window.screen.width < 768
                ? ".swiper-button-prev-mobile"
                : ".swiper-button-prev-desktop",
          },
          pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
          },
          loop: false,
          effect: "slide",
          spaceBetween: 30,
        });

        aboutSlider.on("init", (swiper) => {
          const btns = document.querySelectorAll(
            ".swiper-pagination-custom .swiper-pagination-switch"
          );
          btns.forEach((btn) => {
            btn.classList.remove("active");
          });
          btns[0].classList.add("active");
        });

        const listItems = document.querySelectorAll('.swiper-pagination-custom-mobile li');
        

        aboutSlider.on("slideChangeTransitionStart", (swiper) => {
          let currentIndex = aboutSlider.realIndex;
          if(listItems){
            listItems.forEach((item)=> {
              item.classList.add('d-none');
            });
            // Show the next item
            listItems[currentIndex].classList.remove('d-none');
          }


          const btns = document.querySelectorAll(
            ".swiper-pagination-custom .swiper-pagination-switch"
          );
          btns.forEach((btn) => {
            btn.classList.remove("active");
          });
          btns[swiper.realIndex].classList.add("active");

          

        });

        const customSlideBtns = document.querySelectorAll(
          ".swiper-pagination-custom .swiper-pagination-switch"
        );
        customSlideBtns.forEach((button) => {
          button.addEventListener("click", function () {
            const currentActive = document.querySelector(
              ".swiper-pagination-switch.active"
            );
            if (currentActive) {
              currentActive.classList.remove("active");
            }
            const indx = Array.from(customSlideBtns).indexOf(this);
            aboutSlider.slideTo(indx);

            this.classList.add("active");
          });
        });

        /* window.addEventListener('resize', updateNavigationButtons); */ // Listen for window resize events
      }
    };

    initAboutSlider();

    //Solutions details slider
    const solDetailsSlider = document.querySelector(
      ".solutions-details-slider"
    );
    if (solDetailsSlider) {
      const aboutSlider = new Swiper(solDetailsSlider, {
        modules: [Navigation],
        autoplay: false,
        speed: 500,
        slidesPerView: 1.1,
        direction: "horizontal",
        navigation: {
          nextEl: ".sol-details-next",
          prevEl: ".sol-details-prev",
        },
        loop: false,
        effect: "slide",
        spaceBetween: 30,
        breakpoints: {
          992: {
            slidesPerView: 2.3,
          },
        },
      });
    }
    const searchResultsSlider = document.querySelector(
      ".search-results-content-slider"
    );
    if (searchResultsSlider) {
      new Swiper(searchResultsSlider, {
        modules: [Navigation, Pagination],
        autoplay: false,
        speed: 500,
        slidesPerView: 1,
        direction: "horizontal",
        navigation: {
          nextEl: ".search-results-next",
          prevEl: ".search-results-prev",
        },
        pagination: {
          el: ".search-results-page-info",
          type: "fraction",
          formatFractionCurrent: (number) => `0${number}`,
          formatFractionTotal: (number) => `0${number}`,
        },
        loop: false,
        effect: "slide",
        spaceBetween: 100,
      });
    }
  } catch (error) {
    console.log(error);
  }

  // Additional sliders (slider3, slider4) can be initialized similarly.
}
