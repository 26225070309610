import { CountUp } from "countup.js";
import { Odometer } from "odometer_countup";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function initializeCounters() {
    const counterSection = document.querySelector(".counter-section");

    if (counterSection) {
        setTimeout(() => {
            gsap.from(".count-sup", {
                scrollTrigger: {
                    trigger: counterSection,
                    start: "top 75%",
                    end: "top 20%",
                    scrub: 0.5,
                },
                y: "100%",
                skewY: -15,
                autoAlpha: 0,
                ease: "expo.out",
            });

            // Initialize CountUp.js with Odometer plugin for all counters
            const counters = document.querySelectorAll(".content-count");
            if (counters.length > 0) {
                counters.forEach((counter) => {
                    const endValue = parseFloat(counter.textContent.replace(/[^\d.]/g, ""));
                    
                    // Create a CountUp instance without starting it
                    const countUp = new CountUp(counter, endValue, {
                        plugin: new Odometer({ duration: 0.8, lastDigitDelay: 0 }),
                        duration: 0.8,
                        onStart: function () {
                            counter.textContent = "0";
                        },
                    });

                    // Use GSAP's ScrollTrigger to start the CountUp animation
                    ScrollTrigger.create({
                        trigger: counterSection,
                        start: "top 75%",
                        end: "top 20%",
                        onEnter: () => {
                            if (!countUp.error) {
                                countUp.start();
                            } else {
                                console.error(countUp.error);
                            }
                        },
                        onLeaveBack: () => {
                            countUp.reset();
                        },
                        once: false,
                    });
                });
            }
        }, 500);
    }
}
