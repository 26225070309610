import "../scss/styles.scss";
import { initializeLenis } from "./lenisSetup";
import { initializeSliders } from "./sliderSetup";
import { initializeNavbar } from "./navbar";
import { dynamicSearchRes } from "./dynamicSearchResults";
import { animateSeperator } from "./seperatorAnimation";
import { initializeSpotlightAnimation } from "./spotlightAnimation";
import { initializeCounters } from "./counterAnimation";
import { initializeAccordion } from "./accordion";
import { initializeFormToggle } from "./connect";
import { initializeLoadMoreBTN } from "./loadMoreBTN";
import { initiativesAnimation } from "./initiativesAnimation";
import { initializeDropDown } from "./dropDown";
import { initializeForms } from "./forms";
import { searchInput } from "./searchResults";
//import { initializeSwiper } from './aboutSlider';
import { initializeScrollUpdater } from "./scrollUpdater";
import { pinContainerWithScroll } from "./pinContainerWithScroll";
import { mapList } from "./mapList";
import { solutionsAnimation } from "./solutionsAnimation";
import { productsAnimation } from "./productsAnimation";

document.addEventListener("DOMContentLoaded", function () {
    //initializeLenis();
    const lenis = initializeLenis();
    initializeSliders();
    initializeNavbar();
    dynamicSearchRes();
    animateSeperator();
    initializeSpotlightAnimation();
    initializeCounters();
    initializeAccordion();
    initializeFormToggle();
    initializeLoadMoreBTN();
    solutionsAnimation();
    productsAnimation();
    initiativesAnimation();
    searchInput();
    initializeDropDown();
    initializeForms();
    initializeScrollUpdater(lenis);
    pinContainerWithScroll();
    mapList();
});