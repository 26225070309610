import fetchAndRenderData from "./fetchAndRenderData";

export function initializeLoadMoreBTN() {
    const handleLoadMoreClick = (event) => {
        const button = event.currentTarget;
        const apiUrl = button.getAttribute("data-api");
        let templateName = button.getAttribute("data-template");
        const containerId = button.getAttribute("data-containerID");
        const filterID = button.getAttribute("data-filter");
        const limit = parseInt(button.getAttribute("data-limit"));
        let offset = parseInt(button.getAttribute("data-offset"));
        let moreData = "";
        let dataTotalCount;
        let container;

        button.style.display = "inline";

        if (containerId == "tab") {
            container = document.querySelector(".tab-pane.active .wrapper");
            if (container) {
                const dataTag = container.getAttribute("data-cat");
                offset = parseInt(container.getAttribute("data-offset"));
                moreData = `&cat=${dataTag}`;
            }
        } else {
            container = document.getElementById(containerId);
        }

        //for news events page
        if (filterID) {
            var dropdownButton = document.querySelector("#filterDropdown");
            var buttonText = dropdownButton.querySelector(".fw-bold");
            var buttonVal = "";

            // Select the dropdown menu associated with the filter
            const dropdownMenu = document.querySelector(
                `ul[aria-labelledby="filterDropdown"]`
            );
            const liElements = dropdownMenu.querySelectorAll("li");

            const matchingLi = Array.from(liElements).filter((li) => {
                var liText = li.querySelector("a").textContent.trim();
                return liText === buttonText.textContent;
            });

            if (matchingLi.length > 0) {
                buttonVal = matchingLi[0].querySelector("a").getAttribute("data-value");
                moreData += `&filter=${buttonVal}`;
            }
        }

        fetchAndRenderData(templateName, apiUrl, offset, limit, moreData)
            .then((html) => {
                container.innerHTML += html;
                offset += limit;
                if (containerId == "tab") {
                    container.setAttribute("data-offset", offset);
                    if (!filterID) {
                        dataTotalCount = parseInt(
                            container.getAttribute("data-total-count")
                        );
                        if (dataTotalCount) {
                            if (offset >= dataTotalCount) {
                                button.style.display = "none";
                            } else {
                                button.style.display = "inline";
                            }
                        }
                    } else {
                        if (html) {
                            let dataTotalCountMatch = html.match(/data-total-count="(\d+)"/);
                            let dataTotalCount = dataTotalCountMatch
                                ? parseInt(dataTotalCountMatch[1])
                                : null;

                            if (dataTotalCount !== null) {
                                if (offset >= dataTotalCount) {
                                    button.style.display = "none";
                                } else {
                                    button.style.display = "inline";
                                }
                            }
                        }
                    }
                } else {
                    button.setAttribute("data-offset", offset);
                    // Disable button if offset is greater than or equal to total count of data in case on non tab container
                    dataTotalCount = parseInt(button.getAttribute("data-total-count"));
                    if (dataTotalCount) {
                        if (offset >= dataTotalCount) {
                            button.style.display = "none";
                        } else {
                            button.style.display = "inline";
                        }
                    }
                }
            })
            .catch((error) => console.error("Error populating data:", error));
    };

    const btnArr = document.querySelector(".load-more-ajax-btn");

    if (btnArr) {
        btnArr.addEventListener("click", handleLoadMoreClick);

        // Disable button if offset is greater than or equal to total count of data on page load
        const containerId = btnArr.getAttribute("data-containerID");
        const filterID = btnArr.getAttribute("data-filter");
        //let templateName = btnArr.getAttribute("data-template");
        btnArr.style.display = "inline";
        let dataTotalCount;
        let dataOffset;
        if (containerId) {
            if (containerId == "tab") {
                let container = document.querySelector(".tab-pane.active .wrapper");
                dataOffset = parseInt(container.getAttribute("data-offset"));
                if (!filterID) {
                    dataTotalCount = parseInt(container.getAttribute("data-total-count"));
                    if (dataTotalCount && dataOffset) {
                        if (dataOffset >= dataTotalCount) {
                            btnArr.style.display = "none";
                        } else {
                            btnArr.style.display = "inline";
                        }
                    }
                } else {
                    let template = container.innerHTML;
                    if (template) {
                        let dataTotalCountMatch = template.match(
                            /data-total-count="(\d+)"/
                        );
                        let dataTotalCount = dataTotalCountMatch
                            ? parseInt(dataTotalCountMatch[1])
                            : null;
                            
                        if (dataTotalCount !== null) {
                            if (dataOffset >= dataTotalCount) {
                                btnArr.style.display = "none";
                            } else {
                                btnArr.style.display = "inline";
                            }
                        }
                    }
                }
            } else {
                dataTotalCount = parseInt(btnArr.getAttribute("data-total-count"));
                dataOffset = parseInt(btnArr.getAttribute("data-offset"));
                if (dataTotalCount && dataOffset) {
                    if (dataOffset >= dataTotalCount) {
                        btnArr.style.display = "none";
                    } else {
                        btnArr.style.display = "inline";
                    }
                }
            }
        }
    }

    const tabLinks = document.querySelectorAll('button[data-bs-toggle="tab"]');

    if (tabLinks.length > 0) {
        tabLinks.forEach((tab) => {
            tab.addEventListener("shown.bs.tab", () => {
                const activeTabPane = document.querySelector(
                    ".tab-pane.active .wrapper"
                );
                const button = document.querySelector(".load-more-ajax-btn");
                if (button) {
                    const buttonFilterID = button.getAttribute("data-filter");
                    const offset = parseInt(activeTabPane.getAttribute("data-offset"));
                    //const templateName = button.getAttribute("data-template");
                    let dataTotalCount;
                    if (buttonFilterID) {
                        if (activeTabPane) {
                            let template = activeTabPane.innerHTML;
                            let dataTotalCountMatch = template.match(
                                /data-total-count="(\d+)"/
                            );
                            dataTotalCount = dataTotalCountMatch
                                ? parseInt(dataTotalCountMatch[1])
                                : null;
                        }
                    } else {
                        dataTotalCount = parseInt(
                            activeTabPane.getAttribute("data-total-count")
                        );
                    }

                    // Re-enable or disable the button based on offset and total count

                    if (dataTotalCount) {
                        if (offset >= dataTotalCount) {
                            button.style.display = "none";
                        } else {
                            button.style.display = "inline";
                        }
                    }
                }
            });
        });
    }
}
