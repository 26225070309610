import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function pinContainerWithScroll() {
  const pinAnimationtriggerEle = document.querySelector(
    ".portfolio-solutions-implemented-container"
  );

  const menuLinks = document.querySelectorAll(".left-section-links a");

  const rootFontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  ); // Get root font size in pixels

  const rightSection = document.querySelector(
    ".OurCapabilitiesCard .right-section"
  );

  let rightSectionHeight = rightSection ? rightSection.offsetHeight : 0;

  const rightContainerWrapper = document.querySelector(
    ".right-section .right-wrapper"
  );

  let rightContainerWrapperHeight = rightContainerWrapper
    ? rightContainerWrapper.scrollHeight
    : 0;

  let totalScrollHeight;

  let pinContainerScrollTriggerInstance;

  function updateTweenAndScrollTrigger(newScrollHeight) {
    const rightContainer = document.querySelector(
      ".OurCapabilitiesCard .right"
    );

    const tween = gsap.to(rightContainer, {
      overflow: "visible",
      y: -newScrollHeight,
      ease: "none",
    });

    gsap.registerPlugin(ScrollTrigger);

    if (pinContainerScrollTriggerInstance) {
      pinContainerScrollTriggerInstance.kill();
    }

    pinContainerScrollTriggerInstance = ScrollTrigger.create({
      trigger: pinAnimationtriggerEle,
      start: () => (window.innerWidth < 768 ? "top top" : "top 70px"),
      end: "+=" + newScrollHeight,
      animation: tween,
      scrub: 0.5,
      pin: true,
      pinSpacing: true,
      //markers: true,
      onEnterBack: () => {
        if (pinContainerScrollTriggerInstance.isActive) {
          const lastMenuItem = menuLinks[menuLinks.length - 1].closest("li");
          lastMenuItem.scrollIntoView();
        }
      },
    });

    ScrollTrigger.refresh();
  }

  window.onload = function () {
    if (pinAnimationtriggerEle) {
      totalScrollHeight = 0;

      menuLinks.forEach((link) => {
        const closestLi = link.closest("li");
        if (closestLi) {
          closestLi.classList.add("active");
        }

        const targetId = link.getAttribute("href").substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          totalScrollHeight += targetElement.offsetHeight;
        }
      });

      totalScrollHeight += 1.5 * rootFontSize * (menuLinks.length - 1);
      totalScrollHeight = totalScrollHeight - rightSectionHeight + 40;
      updateTweenAndScrollTrigger(totalScrollHeight);

      // Manually check which section is active on page load
      const sections = document.querySelectorAll(".right-scroll-section-content");
      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const scrollContainerBottom = rightContainerWrapper.getBoundingClientRect().bottom;

        // If the section is in view (or partially in view), set it as active
        if (sectionTop < scrollContainerBottom && sectionTop >= 0) {
          const sectionId = section.id;
          const activeLink = document.querySelector(`a[href="#${sectionId}"]`);

          removeActiveClasses();
          if (activeLink) {
            activeLink.closest("li").classList.add("active");
          }
        }
      });
    }
  };

  function removeActiveClasses() {
    menuLinks.forEach((link) => {
      link.closest("li").classList.remove("active");
    });
  }

  if (pinAnimationtriggerEle) {
    totalScrollHeight = rightContainerWrapperHeight;

    updateTweenAndScrollTrigger(totalScrollHeight);

    // Intersection observer to track active menu link and navigate to the respective section
    const sections = document.querySelectorAll(".right-scroll-section-content");
    const scrollContainer = rightContainerWrapper;

    const rightSectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id;
            const activeLink = document.querySelector(
              `a[href="#${sectionId}"]`
            );

            removeActiveClasses();
            if (activeLink) {
              activeLink.closest("li").classList.add("active");
              if (pinContainerScrollTriggerInstance.isActive) {
                activeLink.closest("li").scrollIntoView();
              }
            }
          }
        });
      },
      {
        root: scrollContainer, // Specific to the right scroll section
        threshold: 0.1,
      }
    );

    sections.forEach((section) => rightSectionObserver.observe(section));

    menuLinks.forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        removeActiveClasses();
        this.closest("li").classList.add("active");

        const targetId = this.getAttribute("href").substring(1);
        const targetElement = document.getElementById(targetId);

        rightSectionObserver.disconnect();

        window.scroll({
          top:
            targetElement.getBoundingClientRect().top +
            window.scrollY -
            scrollContainer.getBoundingClientRect().top,
          behavior: "smooth",
        });

        // Re-observe the sections after 800ms to avoid changing the active link while scrolling
        setTimeout(() => {
          sections.forEach((section) => rightSectionObserver.observe(section));
        }, 800);
      });
    });
  }
  // Smooth scroll for the tabs
  document.querySelectorAll('.nav-tabs .nav-link').forEach(tab => {
    tab.addEventListener('click', function () {
      const container = document.querySelector('.nav-tabs');
      const containerRect = container.getBoundingClientRect();
      const elementRect = this.getBoundingClientRect();
      
      // Calculate the element's offset relative to the container's visible area
      const offset = elementRect.left - containerRect.left;
      
      // Calculate target scroll position taking the container's current scroll into account
      let targetScrollLeft = container.scrollLeft + offset - (container.clientWidth / 2 - elementRect.width / 2);
      
      // Clamp the target scroll value to avoid overshooting the container's bounds
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      targetScrollLeft = Math.max(0, Math.min(targetScrollLeft, maxScrollLeft));
      
      console.log(targetScrollLeft, container.clientWidth, offset, elementRect.width);
      
      container.scrollTo({
        left: targetScrollLeft,
        behavior: 'smooth'
      });
    });
  });
  
  
}

// window.addEventListener("resize", () => {
//   menuLinks.forEach((anchor) => {
//     anchor.addEventListener("click", function (e) {
//       e.preventDefault();
//       removeActiveClasses();
//       this.closest("li").classList.add("active");

//       const targetId = this.getAttribute("href").substring(1);
//       const targetElement = document.getElementById(targetId);

//       const isMobile = window.innerWidth <= 768;
//       const offset = isMobile ? 20 : 0;
//       scrollContainer.scroll({
//         top: targetElement.offsetTop - scrollContainer.offsetTop - offset,
//         behavior: "smooth",
//       });
//     });
//   });
// });
