import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function initializeSpotlightAnimation() {
  gsap.registerPlugin(ScrollTrigger);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  let spotlightSlideWidth;
  let spotlightSlideHeight;
  if (windowWidth < 768) {
    spotlightSlideWidth = windowWidth * 0.75;
    spotlightSlideHeight = windowHeight * 0.75;
  } else {
    spotlightSlideWidth = windowWidth * 0.5;
    spotlightSlideHeight = Math.min(
      spotlightSlideWidth,
      windowHeight * 0.55
    )
  }

  let spotlightContainer = document.querySelector(
    ".home-spotlight-video-container"
  );
  if (spotlightContainer) {
    gsap.set(spotlightContainer, {
      width: spotlightSlideWidth,
      height: spotlightSlideHeight,
    });
  }

  let spotlightAniTrigEle = document.querySelector(".home-spotlight-slide");

  if (spotlightAniTrigEle) {
    let spotlightAniTrigEleTop =
      spotlightAniTrigEle.getBoundingClientRect().top;

    let t1 = gsap.timeline({
      scrollTrigger: {
        trigger: spotlightAniTrigEle,
        start: () => {
          let spotlightAniStartTrigger =
            spotlightAniTrigEleTop + window.scrollY;
          return `top ${spotlightAniStartTrigger}px`;
        },
        end: "center center",
        scrub: 0.5,
        onUpdate: (self) => {
          let progress = self.progress;
          let spotlightVideoContainerWidth;
          let spotlightVideoContainerHeight;

          if (windowWidth < 768) {
            spotlightVideoContainerWidth = 75 + progress * 50;
            spotlightVideoContainerHeight = Math.min((75 + progress * 50), 90);
          } else {
            spotlightVideoContainerWidth = 50 + progress * 50;
            spotlightVideoContainerHeight = 50 + progress * 50;
          }

          gsap.set(spotlightContainer, {
            width: `${spotlightVideoContainerWidth}%`,
            height: `${spotlightVideoContainerHeight}%`,
          });
        },
      },
    });

    const isHomeNavbar = document.getElementById("enable_navbar_animation");
    const animateNavDataOpacity = document.getElementById("animate_nav_data");
    const hamburgerNavSection = document.querySelector(".hamburger-container .hamburger-navigation");

    if (isHomeNavbar) {
      t1.to(isHomeNavbar, {
        translateY: "-100%",
      });
    }

    if (animateNavDataOpacity) {
      t1.to(animateNavDataOpacity, {
        opacity: 1,
      });
    }

    if (isHomeNavbar && hamburgerNavSection && window.innerWidth >= 1024) {
      t1.to(hamburgerNavSection, {
        display: "block",
      });
    }
  }
}
