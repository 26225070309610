export function initializeDropDown() {
    var dropdownItems = document.querySelectorAll('.news-insights-container .dropdown-menu .dropdown-item');
    var dropdownButton = document.querySelector('#filterDropdown');
    var loadMoreBtn = document.querySelector('.load-more-ajax-btn');

    dropdownItems.forEach(function (item) {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            var selectedText = this.textContent;
            var buttonText = dropdownButton.querySelector('.fw-bold');
            buttonText.textContent = selectedText;

            if (loadMoreBtn) {
                const filterValue = this.getAttribute('data-value');
                loadMoreBtn.setAttribute('data-filter', filterValue);
                loadMoreBtn.style.display = "inline";

                const allTabs = ['news', 'insights'];

                allTabs.forEach(function (tabId) {
                    const tabPane = document.getElementById(tabId);
                    const wrapper = tabPane.querySelector('.wrapper');
                    if (wrapper) {
                        wrapper.setAttribute('data-offset', 0);
                        wrapper.innerHTML = '';
                    }
                });

                allTabs.forEach(function (tabId) {
                    const tabPane = document.getElementById(tabId);
                    if (tabPane) {
                        tabPane.classList.add('active');
                        loadMoreBtn.click();
                        tabPane.classList.remove('active');
                    }
                });

                const actualActiveTab = document.querySelector('.nav-link.active');
                if (actualActiveTab) {
                    const tabTarget = actualActiveTab.getAttribute('data-bs-target');
                    document.querySelector(tabTarget).classList.add('active');
                }
            }
        });
    });
}
