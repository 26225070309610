import Mustache from 'mustache';

export default function fetchAndRenderData(templateName, apiUrl, offset, limit, more) {
    return fetch(`${apiUrl}?limit=${limit}&offset=${offset}${more}`)
        .then(response => response.json())
        .then(data => {
            // Get the template source
            const template = document.getElementById(templateName).innerHTML;

            // Generate the HTML for all items
            let html = '';
            data.list.forEach(item => {
                html += Mustache.render(template, item);
            });
            
            // Return the compiled HTML
            return html;
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            throw error;
        });
}