import { Collapse } from "bootstrap";

export function initializeAccordion() {

  let hoverTimeout;
  let leaveTimeout;
  document.querySelectorAll(".accordion-item").forEach(function (item) {
    const header = item.querySelector(".accordion-button");
    const targetCollapse = header.getAttribute("data-bs-target").substring(1);
    const collapseElement = document.getElementById(targetCollapse);

    const prevItem = item.previousElementSibling;


    header.addEventListener("click", function () {
      clearTimeout(hoverTimeout);
      clearTimeout(leaveTimeout);

      item.classList.add("active-item");

      if (prevItem) {
        prevItem.children[0].children[0].style.border = "transparent";
      }

      item.addEventListener("hidden.bs.collapse", function () {

        item.classList.remove("active-item");

        if (prevItem) {
          prevItem.children[0].children[0].style.border = "";
        }
      });


    });

    header.addEventListener("mouseenter", function () {
      // Set a timeout to expand the accordion after 3 seconds
      hoverTimeout = setTimeout(() => {
        const bsCollapse = new Collapse(collapseElement, { toggle: true });
        item.classList.add("active-item");

        
        if (prevItem) {
          prevItem.children[0].children[0].style.border = "transparent";
        }
      }, 1000); // 3000 ms = 3 seconds
    });

    header.addEventListener("mouseleave", function () {
      // Clear the timeout if the user leaves before 3 seconds
      clearTimeout(hoverTimeout);
    });

    item.addEventListener("mouseleave", function () {
      leaveTimeout = setTimeout(() => {
        // Collapse the accordion when the mouse leaves the entire item
        const bsCollapse = Collapse.getInstance(collapseElement); // Get the current instance
        if (bsCollapse && bsCollapse._isShown) {

          // Check if the accordion is open
          bsCollapse.hide(); // Collapse it

          item.classList.remove("active-item");

          if (prevItem) {
            prevItem.children[0].children[0].style.border = "";
          }
        }
      }, 800);
    });
  });
}
