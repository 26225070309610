import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function productsAnimation() {
  const spotlightContainer = document.querySelector(".home-spotlight-section");

  const sectionTitle = document.querySelector(".products-section-title");

  if (spotlightContainer && sectionTitle) {
    gsap.from(sectionTitle, {
      scale: 1.5,
      opacity: 0.4,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: sectionTitle,
        start: "top 90%",
        toggleActions: "play reverse play reverse",
      },
    });
  }

  const tabContainer = document.querySelector(
    ".products-tab-section .tab-container"
  );

  if (spotlightContainer && tabContainer) {
    gsap.from(tabContainer, {
      x: 300,
      opacity: 0.4,
      duration: 1.5,
      delay: 0.4,
      ease: "power2.out",
      scrollTrigger: {
        trigger: tabContainer,
        start: "top 80%",
        end: "bottom 40%",
        toggleActions: "play reverse play reverse",
      },
    });
  }

  const navItems = document.querySelectorAll(
    ".products-tab-section .tab-container .nav-item"
  );

  if (spotlightContainer && navItems.length) {
    gsap.from(navItems, {
      x: 300,
      opacity: 0.4,
      duration: 1.5,
      delay: 0.4,
      ease: "power2.out",
      scrollTrigger: {
        trigger: tabContainer,
        start: "top 80%",
        end: "bottom 40%",
        toggleActions: "play reverse play reverse",
      },
    });
  }

  const buttonContainer = document.querySelector(
    ".products-tab-section .button-container-btn "
  );

  if (spotlightContainer && buttonContainer) {
    gsap.from(buttonContainer, {
      opacity: 0,
      delay: 0.4,
      ease: "power2.out",
      scrollTrigger: {
        trigger: buttonContainer,
        start: "top 95%",
        end: "bottom 40%",
        toggleActions: "play reverse play reverse",
      },
    });
  }

  //Product sticky nav bar
  if (window.matchMedia("(max-width: 768px)").matches) {
    window.addEventListener("scroll", function () {
      const tabContainer = document.querySelector(".tab-container");
      const stickyTabs = document.querySelector(".nav-tabs");
      const tabContent = document.querySelector(".tab-content");
      let lastCard = null;
      
      if(tabContent){
        lastCard = tabContent.querySelector(".product-item");
      }
      

      if (tabContainer && stickyTabs && lastCard) {
        // Get the height of the tab container and the last card
        const tabContainerHeight = tabContainer.offsetHeight;
        const lastCardHeight = lastCard.offsetHeight;

        // Get the distance from the top of the document to the tab container
        const containerTopOffset =
          tabContainer.getBoundingClientRect().top + window.scrollY;

        // The point at which we want to stop the sticky tabs
        const stopStickyPoint =
          containerTopOffset +
          tabContainerHeight -
          lastCardHeight -
          stickyTabs.offsetHeight;

        if (window.scrollY >= stopStickyPoint) {
          // If the user scrolls past the stop point, stop the sticky behavior
          stickyTabs.classList.add("sticky-fixed");
        } else {
          // Otherwise, keep the sticky behavior
          stickyTabs.classList.remove("sticky-fixed");
        }
      }
    });
  }
}
