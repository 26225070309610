import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function initiativesAnimation() {
  gsap.registerPlugin(ScrollTrigger);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const initiativeSlideWidth = windowWidth * 0.6;
  const initiativeSlideHeight = windowHeight * 0.9;

  let initializeAniTrigEle = document.querySelector(".home-initiatives-slide");

  if (initializeAniTrigEle) {
    gsap.set(initializeAniTrigEle, {
      width: initiativeSlideWidth,
      height: initiativeSlideHeight
    });

    // Create the ScrollTrigger
    ScrollTrigger.create({
      trigger: initializeAniTrigEle,
      start: "top 80%", 
      end: "center center", 
      scrub: 0.5,
      onUpdate: (self) => {
        let progress = self.progress;
        let initiativeImgConWidth = 60 + progress * 40; // 60% to 100%
        let initiativeImgConHeight = 90 + progress * 10; // 50% to 100%

        gsap.set(initializeAniTrigEle, {
          width: `${initiativeImgConWidth}%`,
          height: `${initiativeImgConHeight}%`,
        });
      },
    });

    // Animate h2 and h5 elements within the slide
    const h2Element = initializeAniTrigEle.querySelector("h2");
    const h5Element = initializeAniTrigEle.querySelector(".initiatives-description");

    if (h2Element && h5Element) {
      gsap.timeline({
        scrollTrigger: {
          trigger: initializeAniTrigEle,
          start: "top 80%", // Start animation when 80% of the slide is in view
          end: "center center", // End when the center is in view
          scrub: 1, // Allow smooth scrubbing
        }
      })
      .from(h2Element, {
        y: -200, // Translate down from above
        opacity: 0, // Fade in
        duration: 1,
        ease: "power2.out",
      })
      .from(h5Element, {
        y: 200, // Translate up from below
        opacity: 0, // Fade in
        duration: 1,
        ease: "power2.out",
      }, "<"); // "<" means start this animation at the same time as the h2 animation.
    }


  }
}
