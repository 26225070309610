export function initializeFormToggle() {
  const discussForm = document.getElementById("discuss-form");
  const partnerForm = document.getElementById("partner-form");

  const discussFormButton = document.querySelector(".discuss-solutions-btn");
  const partnerFormButton = document.querySelector(".become-partner-btn");

  const discussBackButton = document.getElementById("discuss-back-button");
  const partnerBackButton = document.getElementById("partner-back-button");

  const headerContainer = document.getElementById("connect-header-container");
  const wrapper = document.querySelector(".connect-content-wrapper");

  if (discussFormButton) {
    discussFormButton.addEventListener("click", function () {
      headerContainer.classList.add("heading-active");
      discussForm.classList.add("form-active");
      wrapper.classList.add("form-expanded");
    });
  }

  if (partnerFormButton) {
    partnerFormButton.addEventListener("click", function () {
      headerContainer.classList.add("heading-active");
      partnerForm.classList.add("form-active");
      wrapper.classList.add("form-expanded");
    });
  }

  if (discussBackButton) {
    discussBackButton.addEventListener("click", function () {
      headerContainer.classList.remove("heading-active");
      discussForm.classList.remove("form-active");
      wrapper.classList.remove("form-expanded");
    });
  }

  if (partnerBackButton) {
    partnerBackButton.addEventListener("click", function () {
      headerContainer.classList.remove("heading-active");
      partnerForm.classList.remove("form-active");
      wrapper.classList.remove("form-expanded");
    });
  }
}
