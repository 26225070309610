import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function animateSeperator() {
    gsap.registerPlugin(ScrollTrigger);

    if (window.innerWidth >= 576) {

        const seperatorElements = gsap.utils.toArray(".separator");

        if (seperatorElements.length > 0) {
            seperatorElements.forEach((seperatorElement) => {
                gsap.timeline({
                    scrollTrigger: {
                        trigger: seperatorElement,
                        start: "center 100%",
                        //markers: true,
                        scrub: 0.5,
                    }
                }).to(seperatorElement, {
                    width: "80vw",
                    padding: "0px",
                });
            });
        }
    }
}
