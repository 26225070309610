import Swiper from "swiper";

export function mapList() {
  const mapSolutionsList = document.querySelector(".map-solutions-list");
  const mapContentContainer = document.querySelector(".map-img-wrapper");

  // Loop through each li element
  let activeCard = null;
  let activeWrapper = null;
  if (mapSolutionsList) {

    if (window.matchMedia("(max-width: 768px)").matches) {
      const firstCard = mapSolutionsList.querySelector(".logo-card");
      if (firstCard) {
        firstCard.classList.add("active");
      }
      activeCard = firstCard;
    }

    const mapSwiper =  document.querySelector(".map-swiper");
    
    const solContentWrapper = document.querySelector(
      ".sol-content-wrapper.related-projects"
    );

    const swiperWrapper = document.createElement("div");
    swiperWrapper.className = `map-swiper`;

    

    const swiperContainer = document.createElement("div");
    swiperContainer.className = 'swiper-wrapper';

    swiperWrapper.appendChild(swiperContainer);

    solContentWrapper.appendChild(swiperWrapper);
    
    Array.from(mapSolutionsList.children).forEach((listItem, index) => {
      // Get the data-x, data-y, and data-portfolio-img attributes
      const xPos = listItem.getAttribute("data-x");
      const yPos = listItem.getAttribute("data-y");
      const imgSrc = listItem.getAttribute("data-portfolio-img");

      // Create the div structure with the image
      const divWrapper = document.createElement("div");
      divWrapper.className = `logo-wrapper`;
      divWrapper.style.top = yPos;
      divWrapper.style.left = xPos;

      const imgElement = document.createElement("img");
      imgElement.src = imgSrc;
      imgElement.alt = "Portfolio Logo";

      divWrapper.appendChild(imgElement);
      mapContentContainer.appendChild(divWrapper);
      

      const itemCard = listItem.querySelector(".logo-card");

      if(window.matchMedia("(max-width: 768px)").matches && index === 0 ) {
        divWrapper.classList.add("active");
        activeWrapper = divWrapper;
      }

      
      divWrapper.addEventListener("click", (event) => {
        event.stopPropagation();

        if (activeCard && activeCard !== itemCard) {
          activeCard.parentElement.classList.remove("no-hover");
          activeCard.classList.remove("active");
        }

         // Only add the "active" class to divWrapper on mobile
         if (window.matchMedia("(max-width: 768px)").matches) {
          divWrapper.classList.add("active");
          
          const wrapperList = document.querySelectorAll(".logo-wrapper");
          const indexSlider = Array.prototype.indexOf.call(wrapperList, divWrapper);
          
          productSwiper.slideTo(indexSlider);

          if (activeWrapper && activeWrapper !== divWrapper) {
            activeWrapper.classList.remove("active");
          }
          activeWrapper = divWrapper;
        }

        if (window.matchMedia("(min-width: 768px)").matches) {
          itemCard.classList.add("active");
          itemCard.parentElement.classList.add("no-hover");
        }
        

        const yPosValue = parseInt(yPos, 10);
        const xPosValue = parseInt(xPos, 10);
        if (yPosValue > 50) {
          itemCard.style.bottom = 0;
        } else {
          itemCard.style.top = 0;
        }

        if (xPosValue > 70) {
          itemCard.style.right = 0;
        } else {
          itemCard.style.left = 0;
        }

        activeCard = itemCard;
      });

      swiperContainer.appendChild(itemCard);

      function handleScreenResize() {
        
        if (window.matchMedia("(max-width: 768px)").matches) {
          // If screen width is below 768px, move itemCard to mapContentContainer
          if (divWrapper.contains(itemCard)) {
            swiperContainer.appendChild(itemCard);
          }
        } else {
          // If screen width is above 768px, put itemCard back in divWrapper
          if (!divWrapper.contains(itemCard)) {
            divWrapper.appendChild(itemCard);
          }
        }
      }
      // Initial check
      handleScreenResize();

      // Add resize event listener to handle screen changes dynamically
      window.addEventListener("resize", handleScreenResize);
    });

    const productSwiper = new Swiper(swiperWrapper, {
      direction: "horizontal",
      slidesPerView: 1.05,
      spaceBetween: 15,
      loop: false
    });


    

    document.addEventListener("click", (event) => {
      if (window.matchMedia("(min-width: 768px)").matches) {
        // Check if the click happened outside the active card
        if (activeCard && !activeCard.contains(event.target)) {
          activeCard.classList.remove("active");
          activeCard.parentElement.classList.remove("no-hover");
          activeCard = null;
        }
      }
    });

    productSwiper.on("slideChangeTransitionStart", (swiper) => {
      let currentIndex = productSwiper.realIndex;
      const logoWrappers = document.querySelectorAll('.logo-wrapper');
      if(logoWrappers){
        logoWrappers.forEach((item)=> {
          item.classList.remove('active');
        });
        // Show the next item
        logoWrappers[currentIndex].classList.add('active');
        activeWrapper =   logoWrappers[currentIndex];
      }

      const logoCards = document.querySelectorAll('.logo-card');
      if(logoCards){
        logoCards[currentIndex].classList.add("active");
        activeCard = logoCards[currentIndex];
      }


    });

  }
}
