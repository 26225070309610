import intlTelInput from "intl-tel-input";

export function initializeForms() {
  const inputs = document.querySelectorAll(".phone-input"); // Select all inputs with the class "phone-input"
  inputs.forEach((input) => {
    intlTelInput(input, {
      initialCountry: "ae",
      separateDialCode: true,
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@24.5.0/build/js/utils.js",
      useFullscreenPopup: false,
      strictMode: true,
      hiddenInput: function (telInputName) {
        return {
          phone: "phone",
        };
      },
    });
  });

  // Prevent lenis scroll on dropdown
  const phoneDropdown = document.querySelector(".iti__country-list");
  if (phoneDropdown) {
    phoneDropdown.setAttribute("data-lenis-prevent", "");
  }

  /* Forms custom select start */
  const selectMenus = document.querySelectorAll(".select-container");

  selectMenus.forEach((selectMenu) => {
    if (selectMenu) {
      const selectBtn = selectMenu.querySelector(".select-btn");
      const selectText = selectMenu.querySelector(".sBtn-text");
      selectText.classList.add("placeholder");
      const selectValue = selectMenu.querySelector(".sBtn-value");
      const selectOptions = selectMenu
        .querySelector(".select-options")
        .querySelectorAll("li");

      selectBtn.addEventListener("click", () => {
        selectMenu.classList.toggle("active");
      });

      selectOptions.forEach((option) => {
        option.addEventListener("click", () => {
          const selectedOption = option.textContent;
          selectText.classList.remove("placeholder");
          selectText.textContent = selectedOption;
          selectValue.value = selectedOption;
          selectMenu.classList.remove("active");
        });
      });

      document.addEventListener("click", (event) => {
        if (!selectMenu.contains(event.target)) {
          selectMenu.classList.remove("active");
        }
      });
    }
  });

  /* Forms custom select End  */

  const handlePartnerFormSubmit = (formObj, apiUrl) => {
    formObj.addEventListener("submit", async (event) => {
      event.preventDefault();

      let isValid = true;

      const resetErrorOnInput = (inputElement, errorDiv) => {
        inputElement.addEventListener("input", () => {
          errorDiv.classList.remove("show"); // Clear error message
          inputElement.style.borderColor = ""; // Reset border color
        });
      };
      const resetErrorOnPhoneInput = (inputElement, errorDiv) => {
        inputElement.addEventListener("input", () => {
          errorDiv.classList.remove("show"); // Clear error message
          inputElement.style.outlineColor = ""; // Reset border color
        });
      };

      
      
      // Name validation
      const nameInput = formObj.querySelector('input[name="name"]');
      const nameContainer = nameInput.closest(".input-container");
      const nameErrorDiv = nameContainer.querySelector(".error-message");

      if (nameInput.value.trim() === "") {
        nameErrorDiv.classList.add("show");
        nameInput.style.borderColor = "#A51D26";
        isValid = false;
      }
      resetErrorOnInput(nameInput, nameErrorDiv);

      // Email validation
      const emailInput = formObj.querySelector('input[name="email"]');
      const emailContainer = emailInput.closest(".input-container");
      const emailErrorDiv = emailContainer.querySelector(".error-message");

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format regex
      if (
        emailInput.value.trim() === "" ||
        !emailRegex.test(emailInput.value.trim())
      ) {
        emailErrorDiv.classList.add("show");
        emailInput.style.borderColor = "#A51D26";
        isValid = false;
      }
      resetErrorOnInput(emailInput, emailErrorDiv);

      // Phone number validation
      const phoneInputContainer = formObj.querySelector(".phone-input");
      const phoneInputValue = formObj.querySelector('input[name="phone"]');
      const phoneErrorDiv = phoneInputContainer
        .closest(".input-container")
        .querySelector(".error-message");

      const phoneRegex = /^\+\d+$/;
      if (
        phoneInputValue.value.trim() === "" ||
        !phoneRegex.test(phoneInputValue.value.trim())
      ) {
        phoneErrorDiv.classList.add("show");
        phoneInputContainer.style.outlineColor = "#A51D26";
        isValid = false;
      }
      resetErrorOnPhoneInput(phoneInputContainer, phoneErrorDiv);

      if (!isValid) return;

      const formDataObject = {};
      [...formObj.elements].forEach((element) => {
        if (element.name) {
          formDataObject[element.name] = element.value;
        }
      });

      const jsonData = JSON.stringify(formDataObject);

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "AHsdxj8320Odsds9hHD7789ds6dsd",
          },
          body: jsonData,
        });

        if (!response) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        console.log("Success:", responseData);


        if (responseData.messageOnSubmit === "Thank you") {
          const formSuccess = formObj.parentElement.parentElement.querySelector(".form-success")
          const formWrapper = formObj.parentElement;
        
          if (formSuccess) {
            formSuccess.classList.add("active");
            formWrapper.classList.add("disable");
            setTimeout(() => {
              formObj.reset();
              const selectText = formWrapper.querySelector(".sBtn-text");
              if (selectText) {
                selectText.textContent = "Select your option";
              }

              formSuccess.classList.remove("active");
              formWrapper.classList.remove("disable");
            }, 5000);
          }
        } else {
          const formFail = document.querySelector(".form-fail");
          const formWrapper = formObj.parentElement;
          if (formFail) {
            formFail.classList.add("active");
            formWrapper.classList.add("disable");
            setTimeout(() => {
              formObj.reset();
              formFail.classList.remove("active");
              formWrapper.classList.remove("disable");
            }, 5000);
          }
        }
      } catch (error) {
        const formFail = document.querySelector(".form-fail");
        const formWrapper = formObj.parentElement;
        if (formFail) {
          formFail.classList.add("active");
          formWrapper.classList.add("disable");
          setTimeout(() => {
            formObj.reset();
            formFail.classList.remove("active");
            formWrapper.classList.remove("disable");
          }, 5000);
        }
        console.error("Error:", error);
      }
    });
  };

  //connect form
  const handleConnectFormSubmit = (formObj, apiUrl) => {
    formObj.addEventListener("submit", async (event) => {
      event.preventDefault();

      let isValid = true;

      const resetErrorOnInput = (inputElement, errorDiv) => {
        inputElement.addEventListener("input", () => {
          errorDiv.classList.remove("show"); // Clear error message
          inputElement.style.borderColor = ""; // Reset border color
        });
      };
      const resetErrorOnPhoneInput = (inputElement, errorDiv) => {
        inputElement.addEventListener("input", () => {
          errorDiv.classList.remove("show"); // Clear error message
          inputElement.style.outlineColor = ""; // Reset border color
        });
      };

      // Name validation
      const nameInput = formObj.querySelector('input[name="fullName"]');
      const nameContainer = nameInput.closest(".input-container");
      const nameErrorDiv = nameContainer.querySelector(".error-message");

      if (nameInput.value.trim() === "") {
        nameErrorDiv.classList.add("show");
        nameInput.style.borderColor = "#A51D26";
        isValid = false;
      }
      resetErrorOnInput(nameInput, nameErrorDiv);

      // Company validation
      const companyInput = formObj.querySelector('input[name="companyName"]');
      const companyContainer = companyInput.closest(".input-container");
      const companyErrorDiv = companyContainer.querySelector(".error-message");

      if (companyInput.value.trim() === "") {
        companyErrorDiv.classList.add("show");
        companyInput.style.borderColor = "#A51D26";
        isValid = false;
      }
      resetErrorOnInput(companyInput, companyErrorDiv);

      // Email validation
      const emailInput = formObj.querySelector('input[name="email"]');
      const emailContainer = emailInput.closest(".input-container");
      const emailErrorDiv = emailContainer.querySelector(".error-message");

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (
        emailInput.value.trim() === "" ||
        !emailRegex.test(emailInput.value.trim())
      ) {
        emailErrorDiv.classList.add("show");
        emailInput.style.borderColor = "#A51D26";
        isValid = false;
      }
      resetErrorOnInput(emailInput, emailErrorDiv);

      // Phone number validation
      const phoneInputContainer = formObj.querySelector(".phone-input");
      const phoneInputValue = formObj.querySelector('input[name="phone"]');
      const phoneErrorDiv = phoneInputContainer
        .closest(".input-container")
        .querySelector(".error-message");

      const phoneRegex = /^\+\d+$/;
      if (
        phoneInputValue.value.trim() === "" ||
        !phoneRegex.test(phoneInputValue.value.trim())
      ) {
        phoneErrorDiv.classList.add("show");
        phoneInputContainer.style.outlineColor = "#A51D26";
        isValid = false;
      }
      resetErrorOnPhoneInput(phoneInputContainer, phoneErrorDiv);

      // Textarea validation (Message Box)
      const messageInput = formObj.querySelector('textarea[name="message"]');
      const messageErrorDiv = messageInput
        .closest(".input-container")
        .querySelector(".error-message");

      if (messageInput.value.trim() === "") {
        messageErrorDiv.classList.add("show");
        messageInput.style.borderColor = "#A51D26";
        isValid = false;
      }
      resetErrorOnInput(messageInput, messageErrorDiv);

      if (!isValid) return;

      const formDataObject = {};
      [...formObj.elements].forEach((element) => {
        if (element.name) {
          formDataObject[element.name] = element.value;
        }
      });

      const jsonData = JSON.stringify(formDataObject);

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "AHsdxj8320Odsds9hHD7789ds6dsd",
          },
          body: jsonData,
        });

        if (!response) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        console.log("Success:", responseData);

        if (responseData.messageOnSubmit === "Thank you") {
          const formSuccess = formObj.parentElement.parentElement.querySelector(".form-success")
          const formWrapper = formObj.parentElement;
          if (formSuccess) {
            formSuccess.classList.add("active");
            formWrapper.classList.add("disable");
            setTimeout(() => {
              formObj.reset();
              formSuccess.classList.remove("active");
              formWrapper.classList.remove("disable");
            }, 5000);
          }
        } else {
          const formFail = document.querySelector(".form-fail");
          const formWrapper = formObj.parentElement;
          if (formFail) {
            formFail.classList.add("active");
            formWrapper.classList.add("disable");
            setTimeout(() => {
              formObj.reset();
              formFail.classList.remove("active");
              formWrapper.classList.remove("disable");
            }, 5000);
          }
        }

      } catch (error) {
        const formFail = document.querySelector(".form-fail");
        const formWrapper = formObj.parentElement;
        if (formFail) {
          formFail.classList.add("active");
          formWrapper.classList.add("disable");
          setTimeout(() => {
            formObj.reset();
            formFail.classList.remove("active");
            formWrapper.classList.remove("disable");
          }, 5000);
        }
        console.error("Error:", error);
      }

      console.log("Form is valid");
      // You can proceed with submission logic here
    });
  };

  const feFormUrl = "https://fe-sa-dev.azurewebsites.net/";

  const partnerForm = document.getElementById("partnerForm");

  if (partnerForm) {
    handlePartnerFormSubmit(partnerForm, feFormUrl+partnerForm.getAttribute("umb-form-id"));
  }
  const discussForm = document.getElementById("discussForm");

  if (discussForm) {
    handlePartnerFormSubmit(discussForm, feFormUrl+discussForm.getAttribute("umb-form-id"));
  }

  const connectForm = document.getElementById("connectForm");

  if (connectForm) {
    handleConnectFormSubmit(connectForm, feFormUrl+connectForm.getAttribute("umb-form-id"));
  }
}
