export function searchInput() {
  const searchForm = document.querySelector(".search-results-form");
  const searchInputField = document.querySelector(".search-results-form-input");
  const categorySelect = document.querySelector(".category-select");

  if (searchForm && searchInputField && categorySelect) {
      const urlParams = new URLSearchParams(window.location.search);
      const keyword = urlParams.get("keyword");

      if (keyword) {
          searchInputField.value = keyword;
      }
  }
}
