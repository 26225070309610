import Mustache from "mustache";

export function dynamicSearchRes() {
  let defaultNavSearchInput = document.querySelector(
    "#default_navbar .navbar-actions .nav-search-action-form .nav-search-form-input"
  );
  if (!defaultNavSearchInput) {
    defaultNavSearchInput = document.querySelector(
      "#enable_navbar_animation .navbar-actions .nav-search-action-form .nav-search-form-input"
    );
  }

  let defaultNavSearchResultsContainer = document.querySelector(
    "#default_navbar .navbar-actions .live-search-results-container"
  );
  if (!defaultNavSearchResultsContainer) {
    defaultNavSearchResultsContainer = document.querySelector(
      "#enable_navbar_animation .navbar-actions .live-search-results-container"
    );
  }

  const homeNavSearchInput = document.querySelector(
    "#home_navbar .navbar-actions .nav-search-action-form .nav-search-form-input"
  );
  const homeNavSearchResultsContainer = document.querySelector(
    "#home_navbar .navbar-actions .live-search-results-container"
  );

  const hamburgerMenu = document.querySelector(".hamburger-container");
  const hamburgerSearchMenuContainer = hamburgerMenu.querySelector(
    ".ham-search-menu-container"
  );
  const hamburgerSearchActionForm = hamburgerSearchMenuContainer.querySelector(
    ".ham-search-action-form"
  );
  const hamburgerSearchInput = hamburgerSearchActionForm.querySelector(
    ".ham-search-form-input"
  );
  const hamburgerSearchResultsContainer =
    hamburgerSearchMenuContainer.querySelector(
      ".live-search-results-container"
    );

  function hideAndClearNavSearchActionForm(searchForm) {
    if (searchForm) {
      searchForm.classList.remove("show-search-action-form");
      const searchInput = searchForm.querySelector("input");
      const liveSearchResultsContainer = searchForm.querySelector(
        ".live-search-results-container"
      );
      const searchButton = searchForm.querySelector(
        ".search-form-button-with-icon"
      );

      if (searchInput) {
        searchInput.value = "";
      }

      if (liveSearchResultsContainer) {
        liveSearchResultsContainer.style.display = "none";
      }
      if (searchButton) {
        searchButton.disabled = true;
      }
    }
  }

  function closeHamburgerMenuAndClearSearchForm() {
    hamburgerSearchMenuContainer.classList.remove("toggle-ham-search-menu");
    const inputField = hamburgerSearchActionForm.querySelector("input");
    if (inputField) {
      inputField.value = "";
    }

    const searchResultsContainer = hamburgerSearchActionForm.querySelector(
      ".live-search-results-container"
    );
    if (searchResultsContainer) {
      searchResultsContainer.style.display = "none";
    }
    hamburgerMenu.classList.remove("toggle-hamburger");
  }

  // Handle clicking on search result items
  function handleDynamicSearchResultItemClick(searchForm) {
    if (searchForm === hamburgerSearchActionForm) {
      closeHamburgerMenuAndClearSearchForm();
    } else {
      hideAndClearNavSearchActionForm(searchForm);
    }
  }

  // Attach event listeners to search result links
  function attachSearchResultAndSubmitListeners(searchForm, resultsContainer) {
    const resultLinks = resultsContainer.querySelectorAll(".result-item-link");
    resultLinks.forEach((link) => {
      link.addEventListener("click", () =>
        handleDynamicSearchResultItemClick(searchForm)
      );
    });
  }

  // Debounce function to improve efficiency
  function debounce(func, delay) {
    let debounceTimeout;
    return function () {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(func, delay);
    };
  }

  // Fetch search results from API and render them
  function fetchSearchResults(
    query,
    apiURL,
    lang,
    resultsContainer,
    maxResults,
    itemTemplate
  ) {
    const resultsContainerContent =
      resultsContainer.querySelector(".container-content");
    const keywordElement = resultsContainer.querySelector(
      ".container-header .keyword"
    );
    const seeAllResultsButton = resultsContainer.querySelector(
      ".container-footer .see-all-res-button"
    );
    const noResultsFound = resultsContainer.querySelector(
      ".container-footer .no-results"
    );

    if (!query || query.length < 3) {
      resultsContainerContent.innerHTML = "";
      seeAllResultsButton.style.display = "none";
      noResultsFound.style.display = "none";
      resultsContainer.style.display = "none";
      return;
    }

    if (query.length > 8) {
      keywordElement.textContent = query.slice(0, 8) + "...";
    } else {
      keywordElement.textContent = query;
    }

    if (query.length >= 3) {
      fetch(`${apiURL}?keyword=${query}&lang=${lang}`)
        .then((response) => response.json())
        .then((data) => {
          renderSearchResults(
            data,
            query,
            resultsContainer,
            maxResults,
            itemTemplate,
            seeAllResultsButton,
            noResultsFound
          );

          // Attach click event listeners to the rendered results
          let searchForm;
          if (resultsContainer === hamburgerSearchResultsContainer) {
            searchForm = hamburgerSearchActionForm;
          } else {
            searchForm = resultsContainer.closest(".nav-search-action-form");
          }
          attachSearchResultAndSubmitListeners(searchForm, resultsContainer);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          resultsContainer.style.display = "block";
          seeAllResultsButton.style.display = "none";
          noResultsFound.style.display = "block";
        });
    }
  }

  function renderSearchResults(
    data,
    query,
    resultsContainer,
    maxResults,
    itemTemplate,
    seeAllResultsButton,
    noResultsFound
  ) {
    const regex = new RegExp(`(${query})`, "gi");
    const resultsContainerContent =
      resultsContainer.querySelector(".container-content");

    if (data && data.length > 0) {
      noResultsFound.style.display = "none";
      let resultsHTML = "";
      const resultsToShow = data.slice(0, maxResults);

      resultsToShow.forEach(({ phrase, url }) => {
        const matchIndex = phrase.toLowerCase().indexOf(query.toLowerCase());
        let truncatedResult = phrase;

        if (matchIndex > 20) {
          truncatedResult = "..." + phrase.slice(matchIndex - 20);
        }

        const highlightedResult = truncatedResult.replace(
          regex,
          '<span class="highlight-keyword">$1</span>'
        );

        const templateData = {
          url: url,
          highlightedPhrase: highlightedResult,
        };

        resultsHTML += Mustache.render(itemTemplate, templateData);
      });

      if (data.length > maxResults) {
        seeAllResultsButton.style.display = "block";
      } else {
        seeAllResultsButton.style.display = "none";
      }

      resultsContainer.style.display = "block";
      resultsContainerContent.innerHTML = resultsHTML;
    } else {
      resultsContainer.style.display = "block";
      resultsContainerContent.innerHTML = "";
      seeAllResultsButton.style.display = "none";
      noResultsFound.style.display = "block";
    }
  }

  // Generalized function to set up search with event listener
  function setUpDynamicSearchFunctionality(inputElement, resultsContainer) {
    if (!inputElement || !resultsContainer) {
      console.error("Input element or results container missing");
      return;
    }
    const apiURL = inputElement.getAttribute("data-api");
    //const maxResults = inputElement.getAttribute("res-limit");
    const lang = document.documentElement.lang;
    const maxResults = 5;
    const searchResItemTemplate = document.getElementById(
      "dynamic-search-res-item-template"
    ).innerHTML;

    inputElement.addEventListener(
      "input",
      debounce(function () {
        const query = inputElement.value.trim();
        fetchSearchResults(
          query,
          apiURL,
          lang,
          resultsContainer,
          maxResults,
          searchResItemTemplate
        );
      }, 300)
    );
  }

  setUpDynamicSearchFunctionality(
    homeNavSearchInput,
    homeNavSearchResultsContainer
  );
  setUpDynamicSearchFunctionality(
    defaultNavSearchInput,
    defaultNavSearchResultsContainer
  );
  setUpDynamicSearchFunctionality(
    hamburgerSearchInput,
    hamburgerSearchResultsContainer
  );
}
