import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function solutionsAnimation() {
  gsap.registerPlugin(ScrollTrigger);

  const h2Element = document.querySelector(".solutions-typography h2");
  if (h2Element) {
    gsap.from(h2Element, {
      y: 200,
      duration: 1.5,
      scrollTrigger: {
        trigger: h2Element,
        start: "top 100%",
        toggleActions: "play none none reverse",
      },
    });
  }

  const buttonContainer = document.querySelector("#solutions-section .button-container-btn ");

  if (buttonContainer) {
    gsap.from(buttonContainer, {
      opacity: 0,
      duration: 2,
      scrollTrigger: {
        trigger: h2Element, 
        stagger: 1,
        start: "top 100%",
        toggleActions: "play none none reverse",
      },
    });
  }

  // Check for accordion elements
  const accordionElements = document.querySelectorAll(".accordion");
  if (accordionElements.length > 0) {
    gsap.from(accordionElements, {
      y: 200,
      duration: 1,
      scrollTrigger: {
        trigger: h2Element, 
        start: "top 100%",
        toggleActions: "play none none reverse",
      },
    });
  }
}
