export function initializeNavbar() {
  const minWidthMediaQuery = window.matchMedia("(min-width: 1024px)");

  const navbarContainer = document.querySelector(".navbar-container");

  const navbarHeight = navbarContainer
    ? navbarContainer.getBoundingClientRect().height
    : 0;

  const navItemSolutions = document.querySelector(
    ".navbar-menu-item_solutions"
  );
  const navSolutionsExpand = document.querySelector(
    ".navbar-item-solutions-expand"
  );
  let isHoveringSolutionsNavItem = false;

  const homeSpotlightSlider = document.getElementById(
    "home_spotlight_container"
  );

  // Adjust the margin of the slider based on whether the menu is expanded to get bounce and elastic easing effect.
  function adjustSpotlightSliderMargin(expanded) {
    const expandedMenuHeight = navSolutionsExpand.scrollHeight;
    const totalSpotlightSliderMargin = navbarHeight + expandedMenuHeight;

    if (expanded) {
      if (window.scrollY < navbarHeight) {
        homeSpotlightSlider.style.marginTop = `${totalSpotlightSliderMargin}px`;
        homeSpotlightSlider.style.transition =
          "margin-top 1s cubic-bezier(0.5, 1.5, 0.6, 0.9)";
      } else {
        homeSpotlightSlider.style.marginTop = navbarHeight + "px";
      }
    } else {
      homeSpotlightSlider.style.marginTop = navbarHeight + "px";
    }
  }

  // Show the expanded Solutions menu.
  function showNavSolutionsExpMenu() {
    // Only show the menu if at the top of the page
    isHoveringSolutionsNavItem = true;
    navSolutionsExpand.style.display = "block";
    setTimeout(() => {
      if (homeSpotlightSlider) {
        adjustSpotlightSliderMargin(true);
      }
      navSolutionsExpand.style.opacity = "1";
      navSolutionsExpand.style.visibility = "visible";
    });
  }

  // Hide the expanded Solutions menu.
  function hideNavSolutionsExpMenu() {
    isHoveringSolutionsNavItem = false;
    if (homeSpotlightSlider) {
      adjustSpotlightSliderMargin(false);
    }
    navSolutionsExpand.style.opacity = "0";
    navSolutionsExpand.style.visibility = "hidden";
    navItemSolutions.classList.remove("hovered-solutions");
    navSolutionsExpand.style.display = "none";
  }

  // Add event listeners for hover and functionality only on wider screens.
  function handleScreenWidthChange(e) {
    if (e.matches) {
      navItemSolutions.addEventListener(
        "mouseover",
        showNavSolutionsExpMenuOnHover
      );
      navbarContainer.addEventListener(
        "mouseover",
        keepNavSolutionsExpMenuVisible
      );
      navbarContainer.addEventListener(
        "mouseleave",
        hideNavSolutionsExpMenuOnLeave
      );
      navSolutionsExpand.addEventListener(
        "mouseover",
        keepNavSolutionsExpMenuVisible
      );
      navSolutionsExpand.addEventListener(
        "mouseleave",
        hideNavSolutionsExpMenuOnLeave
      );

      window.addEventListener("scroll", handleScrollFromTop); // Close the expandable menu when scrolling

      document.addEventListener("click", handleClickOutsideSolMenu); // Close the expandable menu when clicking outside
    } else {
      navItemSolutions.classList.remove("hovered-solutions");
      navSolutionsExpand.style.display = "none";
      removeEventListenersForMenuExpand();
    }
  }

  // Remove event listeners when functionality is disabled.
  function removeEventListenersForMenuExpand() {
    navItemSolutions.removeEventListener(
      "mouseover",
      showNavSolutionsExpMenuOnHover
    );
    navbarContainer.removeEventListener(
      "mouseover",
      keepNavSolutionsExpMenuVisible
    );
    navbarContainer.removeEventListener(
      "mouseleave",
      hideNavSolutionsExpMenuOnLeave
    );
    navSolutionsExpand.removeEventListener(
      "mouseover",
      keepNavSolutionsExpMenuVisible
    );
    navSolutionsExpand.removeEventListener(
      "mouseleave",
      hideNavSolutionsExpMenuOnLeave
    );
    window.removeEventListener("scroll", handleScrollFromTop);
    document.removeEventListener("click", handleClickOutsideSolMenu);
  }

  // Handle hover events on the Solutions menu item
  function showNavSolutionsExpMenuOnHover() {
    // Only allow hover if at the top of the page
    navItemSolutions.classList.add("hovered-solutions");
    showNavSolutionsExpMenu();
  }

  // Keep the expanded menu visible if the mouse is hovering over it.
  function keepNavSolutionsExpMenuVisible() {
    if (isHoveringSolutionsNavItem) {
      showNavSolutionsExpMenu();
    }
  }

  // Hide the expanded menu when the mouse leaves the navbar area.
  function hideNavSolutionsExpMenuOnLeave() {
    if (!navbarContainer.matches(":hover")) {
      hideNavSolutionsExpMenu();
    }
  }

  // Close the expandable menu when clicking outside of it.
  function handleClickOutsideSolMenu(event) {
    if (
      !navbarContainer.contains(event.target) && // Click is outside navbarContainer
      !navSolutionsExpand.contains(event.target) && // Click is outside expanded menu
      !navItemSolutions.contains(event.target) // Allow clicks on Solutions item
    ) {
      hideNavSolutionsExpMenu();
    }
  }

  // Close the expandable menu when scrolling down.
  function handleScrollFromTop() {
    if (window.scrollY > 0) {
      hideNavSolutionsExpMenu();
    }
  }

  // Initial check for screen size.
  handleScreenWidthChange(minWidthMediaQuery);

  // Listen for changes in screen size.
  minWidthMediaQuery.addEventListener("change", handleScreenWidthChange);

  // LANGUAGE CHANGE MENU

  const hamburgerMenu = document.querySelector(".hamburger-container");

  const homeNavLanguageChangeMenu = document.querySelector(
    "#home_navbar .language-change-menu"
  );

  const homeNavLanguageChangeMenuIcon = document.querySelector(
    "#home_navbar .nav_language_toggle_icon"
  );

  let defaultNavLanguageChangeMenuIcon = document.querySelector(
    "#default_navbar .nav_language_toggle_icon"
  );
  if (!defaultNavLanguageChangeMenuIcon) {
    defaultNavLanguageChangeMenuIcon = document.querySelector(
      "#enable_navbar_animation .nav_language_toggle_icon"
    );
  }

  let defaultNavLanguageChangeMenu = document.querySelector(
    "#default_navbar .language-change-menu"
  );
  if (!defaultNavLanguageChangeMenu) {
    defaultNavLanguageChangeMenu = document.querySelector(
      "#enable_navbar_animation .language-change-menu"
    );
  }

  const hamburgerLanguageMenuContainer = hamburgerMenu.querySelector(
    ".ham-language-menu-container"
  );

  const hamburgerLanguageChangeMenu =
    hamburgerLanguageMenuContainer.querySelector(".language-change-menu");

  let lastScrollPosition = window.scrollY;
  const scrollThresholdforLangMenuClose = 50;

  function toggleLanguageChangeMenu(languageMenuElement, iconElement) {
    if (languageMenuElement) {
      languageMenuElement.classList.toggle("show-language-change-menu");
      iconElement.classList.toggle("lang-tog-menu-open"); // Add class for color change
      lastScrollPosition = window.scrollY; // Reset scroll position when menu is toggled
    }
  }

  function hideLanguageChangeMenu(languageMenuElement, iconElement) {
    if (languageMenuElement) {
      languageMenuElement.classList.remove("show-language-change-menu");
      iconElement.classList.remove("lang-tog-menu-open"); // Remove class for color change
    }
  }

  function synchronizeCurrentLanguage(selectedIndex) {
    document
      .querySelectorAll(".language-change-menu .menu-item-container")
      .forEach((item) => {
        item.classList.remove("active");
      });

    const defaultNavLangMenuItems =
      defaultNavLanguageChangeMenu.querySelectorAll(".menu-item-container");

    const homeNavLangMenuItems = homeNavLanguageChangeMenu.querySelectorAll(
      ".menu-item-container"
    );

    const hamburgerLangMenuItems = hamburgerLanguageChangeMenu.querySelectorAll(
      ".menu-item-container"
    );

    if (defaultNavLangMenuItems[selectedIndex]) {
      defaultNavLangMenuItems[selectedIndex].classList.add("active");
    }

    if (homeNavLangMenuItems[selectedIndex]) {
      homeNavLangMenuItems[selectedIndex].classList.add("active");
    }

    if (hamburgerLangMenuItems[selectedIndex]) {
      hamburgerLangMenuItems[selectedIndex].classList.add("active");
    }
  }

  function handleLanguageChangeMenuItemClick(event) {
    const target = event.target.closest(".menu-item-container");
    if (target) {
      const menuItems = Array.from(target.parentElement.children);
      const selectedIndex = menuItems.indexOf(target);

      synchronizeCurrentLanguage(selectedIndex);
      setTimeout(() => {
        hideLanguageChangeMenu(
          homeNavLanguageChangeMenu,
          homeNavLanguageChangeMenuIcon
        );
        hideLanguageChangeMenu(
          defaultNavLanguageChangeMenu,
          defaultNavLanguageChangeMenuIcon
        );
      }, 200);
    }
  }

  function setupLangChangeEventListeners() {
    if (homeNavLanguageChangeMenuIcon) {
      homeNavLanguageChangeMenuIcon.addEventListener("click", () => {
        toggleLanguageChangeMenu(
          homeNavLanguageChangeMenu,
          homeNavLanguageChangeMenuIcon
        );
        hideLanguageChangeMenu(
          defaultNavLanguageChangeMenu,
          defaultNavLanguageChangeMenuIcon
        );
      });
    }

    if (defaultNavLanguageChangeMenuIcon) {
      defaultNavLanguageChangeMenuIcon.addEventListener("click", () => {
        toggleLanguageChangeMenu(
          defaultNavLanguageChangeMenu,
          defaultNavLanguageChangeMenuIcon
        );
        hideLanguageChangeMenu(
          homeNavLanguageChangeMenu,
          homeNavLanguageChangeMenuIcon
        );
      });
    }

    const pinOnScrollTopConatiner = document.querySelector(
      ".portfolio-solutions-implemented-container"
    );

    // Hide language menus on scroll if scroll distance exceeds the threshold
    //Hide search form in solution details and portfolio details pages
    window.addEventListener("scroll", () => {
      const currentScrollPosition = window.scrollY;
      const scrollDistance = Math.abs(
        currentScrollPosition - lastScrollPosition
      );

      if (scrollDistance > scrollThresholdforLangMenuClose) {
        if (pinOnScrollTopConatiner) {
          hideAndClearNavSearchActionForm(defaultNavSearchActionForm);
        }
        hideLanguageChangeMenu(
          homeNavLanguageChangeMenu,
          homeNavLanguageChangeMenuIcon
        );
        hideLanguageChangeMenu(
          defaultNavLanguageChangeMenu,
          defaultNavLanguageChangeMenuIcon
        );
      }
    });

    // Hide menus when clicking outside
    window.addEventListener("click", (event) => {
      if (
        !event.target.closest(".language-change-menu") &&
        !event.target.closest(".nav_language_toggle_icon")
      ) {
        hideLanguageChangeMenu(
          homeNavLanguageChangeMenu,
          homeNavLanguageChangeMenuIcon
        );
        hideLanguageChangeMenu(
          defaultNavLanguageChangeMenu,
          defaultNavLanguageChangeMenuIcon
        );
      }
    });

    // Handle menu item clicks
    document.querySelectorAll(".language-change-menu").forEach((menu) => {
      menu.addEventListener("click", handleLanguageChangeMenuItemClick);
    });
  }

  function onHamburgerLangChangeMenuItemClick(event) {
    handleLanguageChangeMenuItemClick(event);
    setTimeout(() => {
      closeHamburgerMenu();
    }, 200);
  }

  if (hamburgerLanguageChangeMenu) {
    hamburgerLanguageChangeMenu.addEventListener(
      "click",
      onHamburgerLangChangeMenuItemClick
    );
  }

  // Setup event listeners for all screen sizes
  setupLangChangeEventListeners();

  // SEARCH ACTION FORMS

  let defaultNavSearchActionIcon = document.querySelector(
    "#default_navbar .nav_search_action_icon"
  );

  if (!defaultNavSearchActionIcon) {
    defaultNavSearchActionIcon = document.querySelector(
      "#enable_navbar_animation .nav_search_action_icon"
    );
  }

  let defaultNavSearchActionForm = document.querySelector(
    "#default_navbar .nav-search-action-form"
  );

  if (!defaultNavSearchActionForm) {
    defaultNavSearchActionForm = document.querySelector(
      "#enable_navbar_animation .nav-search-action-form"
    );
  }

  const homeNavSearchActionForm = document.querySelector(
    "#home_navbar .nav-search-action-form"
  );

  const homeNavSearchActionIcon = document.querySelector(
    "#home_navbar .nav_search_action_icon"
  );

  const hamburgerSearchMenuContainer = hamburgerMenu.querySelector(
    ".ham-search-menu-container"
  );

  const hamburgerSearchActionForm = hamburgerSearchMenuContainer.querySelector(
    ".ham-search-action-form"
  );

  function showNavSearchActionForm(searchForm) {
    if (searchForm) {
      searchForm.classList.add("show-search-action-form");
      const searchInput = searchForm.querySelector("input");
      if (searchInput) {
        searchInput.focus();
      }
    }
  }

  function hideAndClearNavSearchActionForm(searchForm) {
    if (searchForm) {
      searchForm.classList.remove("show-search-action-form");
      const searchInput = searchForm.querySelector("input");
      const liveSearchResultsContainer = searchForm.querySelector(
        ".live-search-results-container"
      );
      const searchButton = searchForm.querySelector(
        ".search-form-button-with-icon"
      );

      if (searchInput) {
        searchInput.value = "";
      }

      if (liveSearchResultsContainer) {
        liveSearchResultsContainer.style.display = "none";
      }
      if (searchButton) {
        searchButton.disabled = true;
      }
    }
  }

  // Input event listener to handle showing/hiding live search results and enabling/disabling button
  function handleSearchActionFormInputChange(searchForm) {
    const searchInput = searchForm.querySelector("input");
    const liveSearchResultsContainer = searchForm.querySelector(
      ".live-search-results-container"
    );
    const searchButton = searchForm.querySelector(
      ".search-form-button-with-icon"
    );

    if (searchInput) {
      searchInput.addEventListener("input", () => {
        const inputValue = searchInput.value.trim();

        if (inputValue.length > 0) {

          // if (liveSearchResultsContainer) {
          //   liveSearchResultsContainer.style.display = "block";
          // }

          if (searchButton) {
            searchButton.disabled = false;
          }
        } else {
          if (liveSearchResultsContainer) {
            liveSearchResultsContainer.style.display = "none";
          }
          if (searchButton) {
            searchButton.disabled = true;
          }
        }
      });
    }
  }

  // Function to check input on page load and ensure live search containers are hidden
  function initializeSearchActionForm(searchForm) {
    const searchInput = searchForm.querySelector("input");
    const liveSearchResultsContainer = searchForm.querySelector(
      ".live-search-results-container"
    );
    const searchButton = searchForm.querySelector(
      ".search-form-button-with-icon"
    );

    if (searchInput) {
      const inputValue = searchInput.value.trim();

      if (inputValue.length === 0) {
        // Hide live search results and disable button initially if input is empty
        if (liveSearchResultsContainer) {
          liveSearchResultsContainer.style.display = "none";
        }
        if (searchButton) {
          searchButton.disabled = true;
        }
      }
    }
  }

  // Initialize event listeners for all search forms and ensure correct initial state
  [
    defaultNavSearchActionForm,
    homeNavSearchActionForm,
    hamburgerSearchActionForm,
  ].forEach((searchForm) => {
    if (searchForm) {
      handleSearchActionFormInputChange(searchForm); // Attach input event listener for live search results
      initializeSearchActionForm(searchForm); // Check and hide live search results on page load
    }
  });

  // Scroll event to show/hide the search form in the home page
  const isDefaultNavbarAnimated = document.getElementById(
    "enable_navbar_animation"
  );
  if (isDefaultNavbarAnimated) {
    window.addEventListener("scroll", () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        hideAndClearNavSearchActionForm(defaultNavSearchActionForm);
      } else if (scrollPosition < 50) {
        hideAndClearNavSearchActionForm(homeNavSearchActionForm);
      }
    });
  }

  if (defaultNavSearchActionIcon) {
    defaultNavSearchActionIcon.addEventListener("click", () => {
      showNavSearchActionForm(defaultNavSearchActionForm);
    });
  }

  if (homeNavSearchActionIcon) {
    homeNavSearchActionIcon.addEventListener("click", () => {
      showNavSearchActionForm(homeNavSearchActionForm);
    });
  }

  // Event listener for Escape key press to hide search forms
  window.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      hideAndClearNavSearchActionForm(defaultNavSearchActionForm);
      hideAndClearNavSearchActionForm(homeNavSearchActionForm);
    }
  });

  // Event listener for click outside the search forms to hide them
  window.addEventListener("click", (event) => {
    if (
      defaultNavSearchActionForm &&
      !event.target.closest(".nav-search-action-form") &&
      !event.target.closest(".nav_search_action_icon")
    ) {
      hideAndClearNavSearchActionForm(defaultNavSearchActionForm);
    }

    if (
      homeNavSearchActionForm &&
      !event.target.closest(".nav-search-action-form") &&
      !event.target.closest(".nav_search_action_icon")
    ) {
      hideAndClearNavSearchActionForm(homeNavSearchActionForm);
    }
  });

  // HAMBURGER MENU TOGGLES

  const toggleOpenHamburgerMenuIcon = document.querySelectorAll(
    ".nav_hamburger_open_icon"
  );
  const toggleCloseHamburgerMenuIcon = document.querySelector(
    ".ham_hamburger_close_icon"
  );

  const toggleOpenHamLanguageMenuIcon = document.querySelector(
    ".ham_language_menu_open_icon"
  );

  const toggleCloseHamLanguageMenuIcon = document.querySelector(
    ".ham_language_menu_close_icon"
  );

  const toggleOpenHamSearchMenuIcon = document.querySelector(
    ".ham_search_menu_open_icon"
  );

  const toggleCloseHamSearchMenuIcon = document.querySelector(
    ".ham_search_menu_close_icon"
  );

  const hamburgerNavLinkItems = hamburgerMenu.querySelectorAll(
    ".hamburger-nav-link"
  );

  const hamburgerFooterSocialIcons = hamburgerMenu.querySelectorAll(
    ".footer-social-links"
  );

  function openHamburgerMenu() {
    hamburgerMenu.classList.add("toggle-hamburger");
  }

  function closeHamburgerMenu() {
    hamburgerMenu.classList.remove("toggle-hamburger");
    hamburgerLanguageMenuContainer.classList.remove("toggle-ham-language-menu");
    closeHamburgerSearchMenu();
  }

  function closeHamburgerSearchMenu() {
    hamburgerSearchMenuContainer.classList.remove("toggle-ham-search-menu");
    const inputField = hamburgerSearchActionForm.querySelector("input");
    if (inputField) {
      inputField.value = "";
    }

    const searchResultsContainer = hamburgerSearchActionForm.querySelector(
      ".live-search-results-container"
    );
    if (searchResultsContainer) {
      searchResultsContainer.style.display = "none";
    }
  }

  toggleOpenHamburgerMenuIcon.forEach(function (toggleOpenHamburger) {
    toggleOpenHamburger.addEventListener("click", openHamburgerMenu);
  });

  toggleCloseHamburgerMenuIcon.addEventListener("click", closeHamburgerMenu);

  hamburgerNavLinkItems.forEach(function (hamburgerNavLinkItem) {
    hamburgerNavLinkItem.addEventListener("click", function () {
      setTimeout(closeHamburgerMenu, 200);
    });
  });

  hamburgerFooterSocialIcons.forEach(function (hamburgerFooterSocialIcon) {
    hamburgerFooterSocialIcon.addEventListener("click", function () {
      setTimeout(closeHamburgerMenu, 200);
    });
  });

  if (toggleOpenHamLanguageMenuIcon) {
    toggleOpenHamLanguageMenuIcon.addEventListener("click", () => {
      hamburgerLanguageMenuContainer.classList.add("toggle-ham-language-menu");
    });
  }

  if (toggleCloseHamLanguageMenuIcon) {
    toggleCloseHamLanguageMenuIcon.addEventListener("click", () => {
      hamburgerLanguageMenuContainer.classList.remove(
        "toggle-ham-language-menu"
      );
    });
  }

  if (toggleOpenHamSearchMenuIcon) {
    toggleOpenHamSearchMenuIcon.addEventListener("click", () => {
      hamburgerSearchMenuContainer.classList.add("toggle-ham-search-menu");
    });
  }

  if (toggleCloseHamSearchMenuIcon) {
    toggleCloseHamSearchMenuIcon.addEventListener(
      "click",
      closeHamburgerSearchMenu
    );
  }
}
