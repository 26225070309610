import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function initializeScrollUpdater(lenis) {
  // Scroll Progress Indicator

  const scrollUpdateContainer = document.querySelector(
    ".scroll-update-container"
  );

  const scrollProgressIndicator = document.querySelector(
    ".scroll-progress-bar .progress-indicator"
  );

  const rightContainerWrapper = document.querySelector(
    ".right-section .right-wrapper"
  );

  let rightContainerWrapperHeight = rightContainerWrapper
    ? rightContainerWrapper.scrollHeight
    : 0;

  let totalScrollableHeight =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight +
    rightContainerWrapperHeight;

  function scrollProgressUpdate() {
    if (scrollProgressIndicator) {
      let currentScrollPosition = document.documentElement.scrollTop;
      let scrollProgressPercentage =
        (currentScrollPosition / totalScrollableHeight) * 100;
      scrollProgressIndicator.style.width = `${scrollProgressPercentage}%`;
    }
  }

  if (scrollProgressIndicator) {
    window.addEventListener("scroll", scrollProgressUpdate);
  }

  // Navbar Slide Animation

  const defaultNavbar = document.getElementById("default_navbar");
  let navbarHeight = defaultNavbar
    ? defaultNavbar.getBoundingClientRect().height
    : 0;
  let navSlideAniTrigEle = document.querySelector(
    ".portfolio-details-header-container"
  );
  if (!navSlideAniTrigEle) {
    navSlideAniTrigEle = document.querySelector(
      ".page-header-with-image-container"
    );
  }

  if (
    defaultNavbar &&
    navSlideAniTrigEle &&
    scrollUpdateContainer &&
    window.innerWidth >= 768
  ) {
    gsap.registerPlugin(ScrollTrigger);
    let navbarSlideAnimationTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: navSlideAniTrigEle,
        start: `top ${navbarHeight}px`,
        end: "bottom top",
        scrub: 0.5,
        //markers: true,
      },
    });
    navbarSlideAnimationTimeline.to(defaultNavbar, {
      translateY: "-100%",
    });
  }

  // Navbar Hide on Scroll Down and Show on Scroll Up for Mobile

  let lastScrollTop = 0;

  if (window.innerWidth < 768 && scrollUpdateContainer) {
    window.addEventListener("scroll", function () {
      const currentScrollPos =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScrollPos > lastScrollTop) {
        // Scrolling down - hide the navbar
        gsap.to(defaultNavbar, {
          y: "-100%",
          duration: 0.3,
          ease: "power2.out",
        });
      } else {
        // Scrolling up - show the navbar
        gsap.to(defaultNavbar, { y: "0%", duration: 0.3, ease: "power2.out" });
      }

      lastScrollTop = currentScrollPos <= 0 ? 0 : currentScrollPos;
    });
  }

  // Intersection Observer for Scroll Update active section

  const navLinks = document.querySelectorAll(
    ".scroll-update-container .scroll-update-item"
  );
  const contentSections = document.querySelectorAll(
    ".scroll-update-container a[href^='#']"
  );

  function clearActiveClasses() {
    navLinks.forEach((link) => {
      link.classList.remove("active");
    });
  }

  // Only initialize the Intersection Observer and Scroll Updates for screens 768px and above

  if (window.innerWidth >= 768 && scrollUpdateContainer) {
    const scrollUpdateObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const currentSectionId = entry.target.id;
            const currentActiveLink = document.querySelector(
              `a[href="#${currentSectionId}"]`
            );

            clearActiveClasses();
            if (currentActiveLink) {
              currentActiveLink.classList.add("active");
            }
          }
        });
      },
      {
        root: null,
        threshold: 0.4,
      }
    );

    contentSections.forEach((link) => {
      const href = link.getAttribute("href");

      if (href && href !== "#") {
        const sectionElement = document.querySelector(href);
        if (sectionElement) {
          scrollUpdateObserver.observe(sectionElement);
        }
      }
    });

    navLinks.forEach((navItem) => {
      navItem.addEventListener("click", function (event) {
        event.preventDefault();
        clearActiveClasses();
        this.classList.add("active");

        const targetSectionId = this.getAttribute("href").substring(1);
        const targetSectionElement = document.getElementById(targetSectionId);

        if (targetSectionElement) {
          lenis.scrollTo(targetSectionElement, {
            duration: 0.5,
            offset: -navbarHeight,
          });
        }
      });
    });
  }
}

// targetSectionElement.scrollIntoView({
//   behavior: "smooth",
//   block: "start",
// });

// if (targetSectionElement) {
//   const isSmallScreen = window.innerWidth <= 768;
//   const scrollOffset = isSmallScreen ? 20 : 0;
//   window.scrollTo({
//     top: targetSectionElement.offsetTop - scrollOffset - 100,
//     behavior: "smooth",
//   });
// }
